import dynamic from 'next/dynamic';
import {
	BlockContentEditorBlockFragFragment,
	GfFormBlockFragFragment,
	GfFormBlockFragFragmentDoc,
	GfFormsFormFragFragment,
} from '@graphqlTypes/graphql';
import type { WordPressBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksProvider';

const GfFormsForm = dynamic( () => import( './GfFormsForm' ).then( ( mod ) => mod.GfFormsForm ) );

export const GfFormsFormBlock: WordPressBlock<
	GfFormBlockFragFragment & BlockContentEditorBlockFragFragment
> & {
	fragments: {
		entry: unknown;
		key: string;
	};
} = ( { attributes } ) => {
	return attributes?.form && <GfFormsForm form={ attributes?.form as GfFormsFormFragFragment } />;
};

GfFormsFormBlock.displayName = 'GravityformsForm';

GfFormsFormBlock.fragments = {
	entry: GfFormBlockFragFragmentDoc,
	key: 'GfFormBlockFrag',
};
