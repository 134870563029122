import { WordPressTemplate } from '@faustwp/core/dist/mjs/getWordPressProps';
import dynamic from 'next/dynamic';
import { gql } from '@graphqlTypes/gql';

const Component = dynamic( () =>
	import( '../features/WpTemplates/MainPage' ).then( ( mod ) => mod.MainPage )
);

const Index: WordPressTemplate = ( props ) => {
	return <Component { ...props } />;
};

Index.variables = ( { uri } ) => {
	return {
		uri,
	};
};

Index.query = gql( `
	query GetIndexNode($uri: String!) {
		siteConfig {
			...SiteIdentityFrag
			...FooterSettingsFrag
		}
		...PrimaryMenuItemsFrag
		currentPage: nodeByUri(uri: $uri) {
			... on Page {
				...NodeWithSeoFrag
			}
			id
			uri
			... on ContentType {
				...ContentTypeFrag
				...NodeWithSeoFrag
			}
		}
	}
` );

export default Index;
