import dynamic from 'next/dynamic';
import { CoreColumnFragFragmentDoc } from '@graphqlTypes/graphql';
import type { WordPressBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksProvider';

const Component = dynamic( () =>
	import( '../features/WpBlocks/components/CoreColumn' ).then( ( mod ) => mod.CoreColumn )
);

export const CoreColumn: WordPressBlock = ( props ) => {
	return <Component { ...props } />;
};

CoreColumn.fragments = {
	entry: CoreColumnFragFragmentDoc,
	key: `CoreColumnFrag`,
};

CoreColumn.displayName = 'CoreColumn';
CoreColumn.config = {
	name: 'CoreColumn',
};
