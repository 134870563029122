import dynamic from 'next/dynamic';
import { CoreColumnsFragFragmentDoc } from '@graphqlTypes/graphql';
import type { WordPressBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksProvider';

const Component = dynamic( () =>
	import( '../features/WpBlocks/components/CoreColumns' ).then( ( mod ) => mod.CoreColumns )
);

export const CoreColumns: WordPressBlock = ( props ) => {
	return <Component { ...props } />;
};

CoreColumns.fragments = {
	entry: CoreColumnsFragFragmentDoc,
	key: `CoreColumnsFrag`,
};

CoreColumns.config = {
	name: 'CoreColumns',
};
CoreColumns.displayName = 'CoreColumns';
