import dynamic from 'next/dynamic';
import { CoreButtonFragFragmentDoc } from '@graphqlTypes/graphql';
import type { WordPressBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksProvider';

const Component = dynamic( () =>
	import( '../features/WpBlocks/components/CoreButtons' ).then( ( mod ) => mod.CoreButtons )
);

export const CoreButtons: WordPressBlock = ( props ) => {
	return <Component { ...props } />;
};

CoreButtons.fragments = {
	key: `CoreButtonsFrag`,
	entry: CoreButtonFragFragmentDoc,
};
CoreButtons.config = {
	name: 'CoreButtons',
};
CoreButtons.displayName = 'CoreButtons';
