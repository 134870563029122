import { WordPressTemplate } from '@faustwp/core/dist/mjs/getWordPressProps';
import dynamic from 'next/dynamic';
import { gql } from '@graphqlTypes/gql';

const Component = dynamic( () =>
	import( '../features/WpTemplates/FrontPage' ).then( ( mod ) => mod.FrontPage )
);

const FrontPage: WordPressTemplate = ( props ) => {
	return <Component { ...props } />;
};

FrontPage.variables = ( { uri } ) => {
	return {
		uri,
	};
};

FrontPage.query = gql( `
	query GetFrontPageNode($uri: ID!) {
		siteConfig {
			...SiteIdentityFrag
			...FooterSettingsFrag
		}
		...PrimaryMenuItemsFrag
		currentPage: page(id: $uri, idType: URI) {
			id
			uri
			title
			date
			editorBlocks {
				...BlockContentEditorBlockFrag
			}
			...FeaturedImageFrag
			...NodeWithSeoFrag
		}
	}
` );

export default FrontPage;
