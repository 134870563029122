import dynamic from 'next/dynamic';
import { CoreSeparatorFragFragmentDoc } from '@graphqlTypes/graphql';
import type { WordPressBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksProvider';

const Component = dynamic( () =>
	import( '../features/WpBlocks/components/CoreSeparator' ).then( ( mod ) => mod.CoreSeparator )
);

export const CoreSeparator: WordPressBlock = ( props ) => {
	return <Component { ...props } />;
};

CoreSeparator.fragments = {
	key: `CoreSeparatorFrag`,
	entry: CoreSeparatorFragFragmentDoc,
};
CoreSeparator.config = {
	name: 'CoreSeparator',
};
CoreSeparator.displayName = 'CoreSeparator';
