/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n\tfragment EntryHeaderWithMetaFrag on ContentNode {\n\t\tdate\n\t\t... on NodeWithTitle {\n\t\t\ttitle\n\t\t}\n\t\t... on NodeWithAuthor {\n\t\t\tauthor {\n\t\t\t\tnode {\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\t... on NodeWithFeaturedImage {\n\t\t\t...FeaturedImageFrag\n\t\t}\n\t}\n": types.EntryHeaderWithMetaFragFragmentDoc,
    "\n\tfragment EntryHeaderWithoutMetaFrag on ContentNode {\n\t\t... on NodeWithTitle {\n\t\t\ttitle\n\t\t}\n\t\t... on NodeWithFeaturedImage {\n\t\t\t...FeaturedImageFrag\n\t\t}\n\t}\n": types.EntryHeaderWithoutMetaFragFragmentDoc,
    "\n\tfragment FeaturedImageFrag on NodeWithFeaturedImage {\n\t\tfeaturedImage {\n\t\t\tnode {\n\t\t\t\t...MediaItemFrag\n\t\t\t}\n\t\t}\n\t}\n": types.FeaturedImageFragFragmentDoc,
    "\n\tfragment SiteIdentityFrag on SiteConfig {\n\t\tidentity {\n\t\t\taxewpLogoLight {\n\t\t\t\tnode {\n\t\t\t\t\t...MediaItemFrag\n\t\t\t\t}\n\t\t\t}\n\t\t\taxewpLogoSquareLight {\n\t\t\t\tnode {\n\t\t\t\t\t...MediaItemFrag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.SiteIdentityFragFragmentDoc,
    "\n\tfragment FooterSettingsFrag on SiteConfig {\n\t\tfooterSettings {\n\t\t\taxewpFooterContent\n\t\t}\n\t}\n": types.FooterSettingsFragFragmentDoc,
    "\n\tfragment MenuItemNodeFrag on MenuItem {\n\t\tcssClasses\n\t\tdescription\n\t\tid\n\t\tlabel\n\t\tparentId\n\t\tpath\n\t\turi\n\t\tmenu {\n\t\t\tnode {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n": types.MenuItemNodeFragFragmentDoc,
    "\n\tfragment PrimaryMenuItemsFrag on RootQuery {\n\t\tprimaryMenuItems: menuItems(where: { location: PRIMARY }) {\n\t\t\tnodes {\n\t\t\t\t...MenuItemNodeFrag\n\t\t\t}\n\t\t}\n\t}\n": types.PrimaryMenuItemsFragFragmentDoc,
    "\n\tfragment NodeWithSeoFrag on NodeWithRankMathSeo {\n\t\tseo {\n\t\t\t...SeoFrag\n\t\t}\n\t}\n": types.NodeWithSeoFragFragmentDoc,
    "\n\tquery GetBlogFeedQuery {\n\t\tgeneralSettings {\n\t\t\ttitle\n\t\t\tdescription\n\t\t\ttimezone\n\t\t}\n\t\trankMathSettings {\n\t\t\tmeta {\n\t\t\t\tglobal {\n\t\t\t\t\topenGraphImage {\n\t\t\t\t\t\tsourceUrl\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\tposts(where: { orderby: { field: DATE, order: DESC } }, first: 10) {\n\t\t\tnodes {\n\t\t\t\tcontent\n\t\t\t\tdateGmt\n\t\t\t\texcerpt\n\t\t\t\tid\n\t\t\t\tmodifiedGmt\n\t\t\t\ttitle\n\t\t\t\turi\n\t\t\t\tauthor {\n\t\t\t\t\tnode {\n\t\t\t\t\t\tname\n\t\t\t\t\t\turl\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tcategories {\n\t\t\t\t\tnodes {\n\t\t\t\t\t\tname\n\t\t\t\t\t\tslug\n\t\t\t\t\t\turi\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tpageInfo {\n\t\t\t\tendCursor\n\t\t\t\tstartCursor\n\t\t\t\thasPreviousPage\n\t\t\t\thasNextPage\n\t\t\t}\n\t\t}\n\t\tlastModified: posts(where: { orderby: { field: MODIFIED, order: DESC } }, first: 1) {\n\t\t\tnodes {\n\t\t\t\tmodifiedGmt\n\t\t\t}\n\t\t}\n\t}\n": types.GetBlogFeedQueryDocument,
    "\n\tfragment FieldDescriptionFrag on FormField {\n\t\t... on GfFieldWithDescriptionSetting {\n\t\t\tdescription\n\t\t}\n\t\t... on GfFieldWithLabelPlacementSetting {\n\t\t\tdescriptionPlacement\n\t\t}\n\t}\n": types.FieldDescriptionFragFragmentDoc,
    "\n\tfragment FieldWrapperFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithDescriptionSetting {\n\t\t\tdescription\n\t\t}\n\t\t... on GfFieldWithLabelPlacementSetting {\n\t\t\tdescriptionPlacement\n\t\t}\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithLabelSetting {\n\t\t\tlabel\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t}\n": types.FieldWrapperFragFragmentDoc,
    "\n\tfragment HtmlFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithLabelSetting {\n\t\t\tlabel\n\t\t}\n\t\t... on GfFieldWithContentSetting {\n\t\t\tcontent\n\t\t}\n\t}\n": types.HtmlFieldFragFragmentDoc,
    "\n\tfragment FormInputFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithInputMaskSetting {\n\t\t\tinputMaskValue\n\t\t}\n\t\t... on GfFieldWithMaxLengthSetting {\n\t\t\tmaxLength\n\t\t}\n\t\t... on GfFieldWithPlaceholderSetting {\n\t\t\tplaceholder\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithSizeSetting {\n\t\t\tsize\n\t\t}\n\t}\n": types.FormInputFieldFragFragmentDoc,
    "\n\tfragment CheckboxFieldChoiceFrag on GfFieldChoice {\n\t\ttext\n\t\tvalue\n\t\t... on CheckboxFieldChoice {\n\t\t\tisSelected\n\t\t}\n\t}\n": types.CheckboxFieldChoiceFragFragmentDoc,
    "\n\tfragment CheckboxFieldInputFrag on GfFieldInput {\n\t\t... on GfFieldInput {\n\t\t\tid\n\t\t\tlabel\n\t\t\t... on CheckboxInputProperty {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n": types.CheckboxFieldInputFragFragmentDoc,
    "\n\tfragment CheckboxFieldFrag on FormField {\n\t\ttype\n\t\tinputType\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithChoicesSetting {\n\t\t\tchoices {\n\t\t\t\t... on GfFieldChoice {\n\t\t\t\t\t...CheckboxFieldChoiceFrag\n\t\t\t\t}\n\t\t\t}\n\t\t\tinputs {\n\t\t\t\t... on GfFieldInput {\n\t\t\t\t\t...CheckboxFieldInputFrag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.CheckboxFieldFragFragmentDoc,
    "\n\tfragment ConsentFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithLabelSetting {\n\t\t\tlabel\n\t\t}\n\t\t... on GfFieldWithCheckboxLabelSetting {\n\t\t\tcheckboxLabel\n\t\t}\n\t\t... on GfFieldWithDescriptionSetting {\n\t\t\tdescription\n\t\t}\n\t}\n": types.ConsentFieldFragFragmentDoc,
    "\n\tfragment RadioFieldFrag on FormField {\n\t\ttype\n\t\tinputType\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithOtherChoiceSetting {\n\t\t\thasOtherChoice\n\t\t}\n\t\t... on GfFieldWithChoicesSetting {\n\t\t\tchoices {\n\t\t\t\t... on GfFieldChoice {\n\t\t\t\t\ttext\n\t\t\t\t\tvalue\n\t\t\t\t\t... on RadioFieldChoice {\n\t\t\t\t\t\tisSelected\n\t\t\t\t\t\tisOtherChoice\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tinputs {\n\t\t\t\t... on GfFieldInput {\n\t\t\t\t\tid\n\t\t\t\t\tlabel\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.RadioFieldFragFragmentDoc,
    "\n\tfragment SectionFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithLabelSetting {\n\t\t\tlabel\n\t\t}\n\t\t... on GfFieldWithDescriptionSetting {\n\t\t\tdescription\n\t\t}\n\t}\n": types.SectionFieldFragFragmentDoc,
    "\n\tfragment TextAreaFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithInputMaskSetting {\n\t\t\tinputMaskValue\n\t\t}\n\t\t... on GfFieldWithMaxLengthSetting {\n\t\t\tmaxLength\n\t\t}\n\t\t... on GfFieldWithPlaceholderSetting {\n\t\t\tplaceholder\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithSizeSetting {\n\t\t\tsize\n\t\t}\n\t}\n": types.TextAreaFieldFragFragmentDoc,
    "\n\tfragment FormFieldSizeFrag on GfFieldWithSizeSetting {\n\t\tsize\n\t}\n": types.FormFieldSizeFragFragmentDoc,
    "\n\tfragment FormFieldDefaultValueFrag on GfFieldWithDefaultValueSetting {\n\t\tdefaultValue\n\t}\n": types.FormFieldDefaultValueFragFragmentDoc,
    "\n\tfragment FormFieldConditionalLogicFrag on GfFieldWithConditionalLogicSetting {\n\t\tconditionalLogic {\n\t\t\tactionType\n\t\t\tlogicType\n\t\t\trules {\n\t\t\t\tfieldId\n\t\t\t\toperator\n\t\t\t\tvalue\n\t\t\t}\n\t\t}\n\t}\n": types.FormFieldConditionalLogicFragFragmentDoc,
    "\n\tfragment GfFormFieldFrag on FormField {\n\t\tid\n\t\tdatabaseId\n\t\tinputType\n\t\tlayoutGridColumnSpan\n\t\tlayoutSpacerGridColumnSpan\n\t\tpageNumber\n\t\ttype\n\t\tvisibility\n\t\tdisplayOnly\n\t\t... on GfFieldWithConditionalLogicSetting {\n\t\t\t...FormFieldConditionalLogicFrag\n\t\t}\n\t\t... on GfFieldWithSizeSetting {\n\t\t\t...FormFieldSizeFrag\n\t\t}\n\t\t... on GfFieldWithDefaultValueSetting {\n\t\t\t...FormFieldDefaultValueFrag\n\t\t}\n\t\t...CheckboxFieldFrag\n\t\t...ConsentFieldFrag\n\t\t...FieldWrapperFrag\n\t\t...FormInputFieldFrag\n\t\t...HtmlFieldFrag\n\t\t...RadioFieldFrag\n\t\t...SectionFieldFrag\n\t}\n": types.GfFormFieldFragFragmentDoc,
    "\n\tfragment GfFormsFormFrag on GfForm {\n\t\tid\n\t\tdatabaseId\n\t\tdescriptionPlacement\n\t\tlabelPlacement\n\t\tsubLabelPlacement\n\t\ttitle\n\t\tsubmitButton {\n\t\t\tconditionalLogic {\n\t\t\t\tactionType\n\t\t\t\tlogicType\n\t\t\t\trules {\n\t\t\t\t\tfieldId\n\t\t\t\t\toperator\n\t\t\t\t\tvalue\n\t\t\t\t}\n\t\t\t}\n\t\t\timageUrl\n\t\t\ttext\n\t\t\ttype\n\t\t}\n\t\tformFields {\n\t\t\tnodes {\n\t\t\t\t... on FormField {\n\t\t\t\t\t...GfFormFieldFrag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.GfFormsFormFragFragmentDoc,
    "\n\tfragment GfFormBlockFrag on GravityformsForm {\n\t\tattributes {\n\t\t\tform {\n\t\t\t\t...GfFormsFormFrag\n\t\t\t}\n\t\t}\n\t}\n": types.GfFormBlockFragFragmentDoc,
    "\n\tmutation GfFormSubmit($input: SubmitGfFormInput!) {\n\t\tsubmitGfForm(input: $input) {\n\t\t\terrors {\n\t\t\t\tmessage\n\t\t\t\tid\n\t\t\t}\n\t\t\tconfirmation {\n\t\t\t\ttype\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n": types.GfFormSubmitDocument,
    "\n\tfragment LeaderboardUserDataFrag on LeaderboardSubmitter {\n\t\tavatarUrl\n\t\tgithubUrl\n\t\tissueUrls\n\t\tlogin\n\t\tname\n\t\topenIssues\n\t\topenPRs\n\t\ttotalIssues\n\t\ttotalPRs\n\t\ttotalTime\n\t\ttwitter\n\t\twebsite\n\t}\n": types.LeaderboardUserDataFragFragmentDoc,
    "\n\tfragment LeaderboardFrag on LeaderboardData {\n\t\tpendingCount\n\t\ttotalIssueCount\n\t\ttotalPRCount\n\t\ttotalTime\n\t\tusersData {\n\t\t\t...LeaderboardUserDataFrag\n\t\t}\n\t}\n": types.LeaderboardFragFragmentDoc,
    "\n\tfragment ProjectFrag on Project {\n\t\tid\n\t\ttitle\n\t\tprojectLinks {\n\t\t\trepoUrl\n\t\t}\n\t\tprojectTypes {\n\t\t\tnodes {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t\tfeaturedImage {\n\t\t\tnode {\n\t\t\t\tsourceUrl(size: MEDIUM_LARGE)\n\t\t\t\taltText\n\t\t\t\tmediaDetails {\n\t\t\t\t\twidth\n\t\t\t\t\theight\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.ProjectFragFragmentDoc,
    "\n\tfragment ProjectsFrag on RootQueryToProjectConnection {\n\t\tnodes {\n\t\t\t...ProjectFrag\n\t\t}\n\t}\n": types.ProjectsFragFragmentDoc,
    "\n\tfragment SeoFrag on RankMathSeo {\n\t\ttitle\n\t\tdescription\n\t\trobots\n\t\topenGraph {\n\t\t\ttype\n\t\t\tlocale\n\t\t\ttitle\n\t\t\tdescription\n\t\t\timage {\n\t\t\t\tsecureUrl\n\t\t\t\theight\n\t\t\t\twidth\n\t\t\t}\n\t\t\turl\n\t\t\tsiteName\n\t\t}\n\t}\n": types.SeoFragFragmentDoc,
    "\n\tfragment BlockContentEditorBlockFrag on EditorBlock {\n\t\t__typename\n\t\trenderedHtml\n\t\tcssClassNames\n\t\tname\n\t\tclientId\n\t\tparentClientId\n\t\tinnerBlocks {\n\t\t\t__typename\n\t\t}\n\t\t... on CoreButton {\n\t\t\t...CoreButtonFrag\n\t\t}\n\t\t... on CoreButtons {\n\t\t\t...CoreButtonsFrag\n\t\t}\n\t\t... on CoreColumn {\n\t\t\t...CoreColumnFrag\n\t\t}\n\t\t... on CoreColumns {\n\t\t\t...CoreColumnsFrag\n\t\t}\n\t\t... on CoreGroup {\n\t\t\t...CoreGroupFrag\n\t\t}\n\t\t... on CoreHeading {\n\t\t\t...CoreHeadingFrag\n\t\t}\n\t\t... on CoreParagraph {\n\t\t\t...CoreParagraphFrag\n\t\t}\n\t\t... on GravityformsForm {\n\t\t\t...GfFormBlockFrag\n\t\t}\n\t}\n": types.BlockContentEditorBlockFragFragmentDoc,
    "\n\tfragment CoreButtonFrag on CoreButton {\n\t\tattributes {\n\t\t\tanchor\n\t\t\tgradient\n\t\t\ttext\n\t\t\ttextAlign\n\t\t\ttextColor\n\t\t\tstyle\n\t\t\tfontSize\n\t\t\tfontFamily\n\t\t\tlinkTarget\n\t\t\trel\n\t\t\turl\n\t\t\tbackgroundColor\n\t\t\tcssClassName\n\t\t\tlinkClassName\n\t\t}\n\t}\n": types.CoreButtonFragFragmentDoc,
    "\n\tfragment CoreButtonsFrag on CoreButtons {\n\t\tattributes {\n\t\t\tcssClassName\n\t\t\talign\n\t\t\tanchor\n\t\t\tfontFamily\n\t\t\tfontSize\n\t\t\tlayout\n\t\t\tstyle\n\t\t}\n\t}\n": types.CoreButtonsFragFragmentDoc,
    "\n\tfragment CoreColumnFrag on CoreColumn {\n\t\tattributes {\n\t\t\tanchor\n\t\t\tborderColor\n\t\t\tbackgroundColor\n\t\t\tcssClassName\n\t\t\tclassName\n\t\t\tfontFamily\n\t\t\tfontSize\n\t\t\tgradient\n\t\t\tlayout\n\t\t\tstyle\n\t\t\ttextColor\n\t\t\tverticalAlignment\n\t\t\twidth\n\t\t}\n\t}\n": types.CoreColumnFragFragmentDoc,
    "\n\tfragment CoreColumnsFrag on CoreColumns {\n\t\tattributes {\n\t\t\talign\n\t\t\tanchor\n\t\t\tlayout\n\t\t\tcssClassName\n\t\t\tisStackedOnMobile\n\t\t\tverticalAlignment\n\t\t\tborderColor\n\t\t\tbackgroundColor\n\t\t\tfontSize\n\t\t\tfontFamily\n\t\t\tstyle\n\t\t\ttextColor\n\t\t\tgradient\n\t\t}\n\t}\n": types.CoreColumnsFragFragmentDoc,
    "\n\tfragment CoreGroupFrag on CoreGroup {\n\t\trenderedHtml\n\t\tcssClassNames\n\t\tattributes {\n\t\t\talign\n\t\t\tanchor\n\t\t\tbackgroundColor\n\t\t\tborderColor\n\t\t\tclassName\n\t\t\tfontFamily\n\t\t\tfontSize\n\t\t\tgradient\n\t\t\tlayout\n\t\t\tstyle\n\t\t\ttagName\n\t\t\ttextColor\n\t\t}\n\t}\n": types.CoreGroupFragFragmentDoc,
    "\n\tfragment CoreHeadingFrag on CoreHeading {\n\t\tattributes {\n\t\t\talign\n\t\t\tanchor\n\t\t\tbackgroundColor\n\t\t\tcontent\n\t\t\tfontFamily\n\t\t\tfontSize\n\t\t\tgradient\n\t\t\tlevel\n\t\t\tstyle\n\t\t\ttextAlign\n\t\t\ttextColor\n\t\t\tcssClassName\n\t\t}\n\t}\n": types.CoreHeadingFragFragmentDoc,
    "\n\tfragment CoreParagraphFrag on CoreParagraph {\n\t\tcssClassNames\n\t\tattributes {\n\t\t\tcssClassName\n\t\t\tbackgroundColor\n\t\t\tcontent\n\t\t\tstyle\n\t\t\ttextColor\n\t\t\tfontSize\n\t\t\tfontFamily\n\t\t\tdirection\n\t\t\tdropCap\n\t\t\tgradient\n\t\t\talign\n\t\t}\n\t}\n": types.CoreParagraphFragFragmentDoc,
    "\n\tfragment CoreSeparatorFrag on CoreSeparator {\n\t\tcssClassNames\n\t\tattributes {\n\t\t\talign\n\t\t\tanchor\n\t\t\topacity\n\t\t\tgradient\n\t\t\tbackgroundColor\n\t\t\tstyle\n\t\t\tcssClassName\n\t\t}\n\t}\n": types.CoreSeparatorFragFragmentDoc,
    "\n\tfragment PostPageSettingsFrag on Post {\n\t\tpageSettings {\n\t\t\taxewpPageHeader\n\t\t}\n\t}\n": types.PostPageSettingsFragFragmentDoc,
    "\n\tfragment PageSettingsFrag on Page {\n\t\tpageSettings {\n\t\t\taxewpPageHeader\n\t\t}\n\t}\n": types.PageSettingsFragFragmentDoc,
    "\n\tfragment TitleFrag on NodeWithTitle {\n\t\ttitle\n\t}\n": types.TitleFragFragmentDoc,
    "\n\tfragment ContentNodeFrag on ContentNode {\n\t\tdate\n\t}\n": types.ContentNodeFragFragmentDoc,
    "\n\tfragment ContentEditorFrag on NodeWithContentEditor {\n\t\tcontent\n\t}\n": types.ContentEditorFragFragmentDoc,
    "\n\tfragment AuthorNodeFrag on NodeWithAuthor {\n\t\tauthor {\n\t\t\tnode {\n\t\t\t\tname\n\t\t\t\turi\n\t\t\t\tavatar {\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.AuthorNodeFragFragmentDoc,
    "\n\tfragment MediaItemFrag on MediaItem {\n\t\tid\n\t\tsourceUrl\n\t\taltText\n\t\tmediaDetails {\n\t\t\twidth\n\t\t\theight\n\t\t}\n\t}\n": types.MediaItemFragFragmentDoc,
    "\n\tfragment MainProjectFrag on Project {\n\t\ttitle\n\t\tslug\n\t\tprojectLinks {\n\t\t\trepoUrl\n\t\t}\n\t\tprojectTypes {\n\t\t\tnodes {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n": types.MainProjectFragFragmentDoc,
    "\n\tfragment ContentTypeFrag on ContentType {\n\t\tname\n\t\tcontentNodes {\n\t\t\tnodes {\n\t\t\t\t... on Project {\n\t\t\t\t\t...MainProjectFrag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.ContentTypeFragFragmentDoc,
    "\n\tfragment SingularPostCategoryFrag on Category {\n\t\tid\n\t\tname\n\t\turi\n\t}\n\t": types.SingularPostCategoryFragFragmentDoc,
    "\n\tfragment SingularPostFrag on Post {\n\t\tcategories {\n\t\t\tnodes {\n\t\t\t\t...SingularPostCategoryFrag\n\t\t\t}\n\t\t}\n\t}\n": types.SingularPostFragFragmentDoc,
    "\n\tquery Get404Page {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t}\n": types.Get404PageDocument,
    "\n\tquery GetProjectsArchiveNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: page(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\t...TitleFrag\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t\t...PageSettingsFrag\n\t\t}\n\t\tprojects(first: 20, where: { orderby: { field: MENU_ORDER, order: ASC } }) {\n\t\t\t...ProjectsFrag\n\t\t}\n\t}\n": types.GetProjectsArchiveNodeDocument,
    "\n\tquery GetFrontPageNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: page(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\ttitle\n\t\t\tdate\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t}\n\t}\n": types.GetFrontPageNodeDocument,
    "\n\tquery GetIndexNode($uri: String!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: nodeByUri(uri: $uri) {\n\t\t\t... on Page {\n\t\t\t\t...NodeWithSeoFrag\n\t\t\t}\n\t\t\tid\n\t\t\turi\n\t\t\t... on ContentType {\n\t\t\t\t...ContentTypeFrag\n\t\t\t\t...NodeWithSeoFrag\n\t\t\t}\n\t\t}\n\t}\n": types.GetIndexNodeDocument,
    "\n\tquery GetSpringCleaningPageNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: page(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\ttitle\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t\tpageSettings {\n\t\t\t\taxewpPageHeader\n\t\t\t}\n\t\t}\n\t\tgfForm(id: 2, idType: DATABASE_ID) {\n\t\t\t...GfFormsFormFrag\n\t\t}\n\t\tleaderboard {\n\t\t\t...LeaderboardFrag\n\t\t}\n\t}\n": types.GetSpringCleaningPageNodeDocument,
    "\n\tquery GetPageNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: page(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\ttitle\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t\tpageSettings {\n\t\t\t\taxewpPageHeader\n\t\t\t}\n\t\t}\n\t}\n": types.GetPageNodeDocument,
    "\n\tquery GetSingularNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: post(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\ttitle\n\t\t\t...ContentNodeFrag\n\t\t\t...ContentEditorFrag\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t\t...AuthorNodeFrag\n\t\t\t...SingularPostFrag\n\t\t\t...PostPageSettingsFrag\n\t\t}\n\t}\n": types.GetSingularNodeDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment EntryHeaderWithMetaFrag on ContentNode {\n\t\tdate\n\t\t... on NodeWithTitle {\n\t\t\ttitle\n\t\t}\n\t\t... on NodeWithAuthor {\n\t\t\tauthor {\n\t\t\t\tnode {\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\t... on NodeWithFeaturedImage {\n\t\t\t...FeaturedImageFrag\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment EntryHeaderWithMetaFrag on ContentNode {\n\t\tdate\n\t\t... on NodeWithTitle {\n\t\t\ttitle\n\t\t}\n\t\t... on NodeWithAuthor {\n\t\t\tauthor {\n\t\t\t\tnode {\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\t... on NodeWithFeaturedImage {\n\t\t\t...FeaturedImageFrag\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment EntryHeaderWithoutMetaFrag on ContentNode {\n\t\t... on NodeWithTitle {\n\t\t\ttitle\n\t\t}\n\t\t... on NodeWithFeaturedImage {\n\t\t\t...FeaturedImageFrag\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment EntryHeaderWithoutMetaFrag on ContentNode {\n\t\t... on NodeWithTitle {\n\t\t\ttitle\n\t\t}\n\t\t... on NodeWithFeaturedImage {\n\t\t\t...FeaturedImageFrag\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment FeaturedImageFrag on NodeWithFeaturedImage {\n\t\tfeaturedImage {\n\t\t\tnode {\n\t\t\t\t...MediaItemFrag\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment FeaturedImageFrag on NodeWithFeaturedImage {\n\t\tfeaturedImage {\n\t\t\tnode {\n\t\t\t\t...MediaItemFrag\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment SiteIdentityFrag on SiteConfig {\n\t\tidentity {\n\t\t\taxewpLogoLight {\n\t\t\t\tnode {\n\t\t\t\t\t...MediaItemFrag\n\t\t\t\t}\n\t\t\t}\n\t\t\taxewpLogoSquareLight {\n\t\t\t\tnode {\n\t\t\t\t\t...MediaItemFrag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment SiteIdentityFrag on SiteConfig {\n\t\tidentity {\n\t\t\taxewpLogoLight {\n\t\t\t\tnode {\n\t\t\t\t\t...MediaItemFrag\n\t\t\t\t}\n\t\t\t}\n\t\t\taxewpLogoSquareLight {\n\t\t\t\tnode {\n\t\t\t\t\t...MediaItemFrag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment FooterSettingsFrag on SiteConfig {\n\t\tfooterSettings {\n\t\t\taxewpFooterContent\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment FooterSettingsFrag on SiteConfig {\n\t\tfooterSettings {\n\t\t\taxewpFooterContent\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment MenuItemNodeFrag on MenuItem {\n\t\tcssClasses\n\t\tdescription\n\t\tid\n\t\tlabel\n\t\tparentId\n\t\tpath\n\t\turi\n\t\tmenu {\n\t\t\tnode {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment MenuItemNodeFrag on MenuItem {\n\t\tcssClasses\n\t\tdescription\n\t\tid\n\t\tlabel\n\t\tparentId\n\t\tpath\n\t\turi\n\t\tmenu {\n\t\t\tnode {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment PrimaryMenuItemsFrag on RootQuery {\n\t\tprimaryMenuItems: menuItems(where: { location: PRIMARY }) {\n\t\t\tnodes {\n\t\t\t\t...MenuItemNodeFrag\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment PrimaryMenuItemsFrag on RootQuery {\n\t\tprimaryMenuItems: menuItems(where: { location: PRIMARY }) {\n\t\t\tnodes {\n\t\t\t\t...MenuItemNodeFrag\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment NodeWithSeoFrag on NodeWithRankMathSeo {\n\t\tseo {\n\t\t\t...SeoFrag\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment NodeWithSeoFrag on NodeWithRankMathSeo {\n\t\tseo {\n\t\t\t...SeoFrag\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetBlogFeedQuery {\n\t\tgeneralSettings {\n\t\t\ttitle\n\t\t\tdescription\n\t\t\ttimezone\n\t\t}\n\t\trankMathSettings {\n\t\t\tmeta {\n\t\t\t\tglobal {\n\t\t\t\t\topenGraphImage {\n\t\t\t\t\t\tsourceUrl\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\tposts(where: { orderby: { field: DATE, order: DESC } }, first: 10) {\n\t\t\tnodes {\n\t\t\t\tcontent\n\t\t\t\tdateGmt\n\t\t\t\texcerpt\n\t\t\t\tid\n\t\t\t\tmodifiedGmt\n\t\t\t\ttitle\n\t\t\t\turi\n\t\t\t\tauthor {\n\t\t\t\t\tnode {\n\t\t\t\t\t\tname\n\t\t\t\t\t\turl\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tcategories {\n\t\t\t\t\tnodes {\n\t\t\t\t\t\tname\n\t\t\t\t\t\tslug\n\t\t\t\t\t\turi\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tpageInfo {\n\t\t\t\tendCursor\n\t\t\t\tstartCursor\n\t\t\t\thasPreviousPage\n\t\t\t\thasNextPage\n\t\t\t}\n\t\t}\n\t\tlastModified: posts(where: { orderby: { field: MODIFIED, order: DESC } }, first: 1) {\n\t\t\tnodes {\n\t\t\t\tmodifiedGmt\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetBlogFeedQuery {\n\t\tgeneralSettings {\n\t\t\ttitle\n\t\t\tdescription\n\t\t\ttimezone\n\t\t}\n\t\trankMathSettings {\n\t\t\tmeta {\n\t\t\t\tglobal {\n\t\t\t\t\topenGraphImage {\n\t\t\t\t\t\tsourceUrl\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\tposts(where: { orderby: { field: DATE, order: DESC } }, first: 10) {\n\t\t\tnodes {\n\t\t\t\tcontent\n\t\t\t\tdateGmt\n\t\t\t\texcerpt\n\t\t\t\tid\n\t\t\t\tmodifiedGmt\n\t\t\t\ttitle\n\t\t\t\turi\n\t\t\t\tauthor {\n\t\t\t\t\tnode {\n\t\t\t\t\t\tname\n\t\t\t\t\t\turl\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tcategories {\n\t\t\t\t\tnodes {\n\t\t\t\t\t\tname\n\t\t\t\t\t\tslug\n\t\t\t\t\t\turi\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tpageInfo {\n\t\t\t\tendCursor\n\t\t\t\tstartCursor\n\t\t\t\thasPreviousPage\n\t\t\t\thasNextPage\n\t\t\t}\n\t\t}\n\t\tlastModified: posts(where: { orderby: { field: MODIFIED, order: DESC } }, first: 1) {\n\t\t\tnodes {\n\t\t\t\tmodifiedGmt\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment FieldDescriptionFrag on FormField {\n\t\t... on GfFieldWithDescriptionSetting {\n\t\t\tdescription\n\t\t}\n\t\t... on GfFieldWithLabelPlacementSetting {\n\t\t\tdescriptionPlacement\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment FieldDescriptionFrag on FormField {\n\t\t... on GfFieldWithDescriptionSetting {\n\t\t\tdescription\n\t\t}\n\t\t... on GfFieldWithLabelPlacementSetting {\n\t\t\tdescriptionPlacement\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment FieldWrapperFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithDescriptionSetting {\n\t\t\tdescription\n\t\t}\n\t\t... on GfFieldWithLabelPlacementSetting {\n\t\t\tdescriptionPlacement\n\t\t}\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithLabelSetting {\n\t\t\tlabel\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment FieldWrapperFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithDescriptionSetting {\n\t\t\tdescription\n\t\t}\n\t\t... on GfFieldWithLabelPlacementSetting {\n\t\t\tdescriptionPlacement\n\t\t}\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithLabelSetting {\n\t\t\tlabel\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment HtmlFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithLabelSetting {\n\t\t\tlabel\n\t\t}\n\t\t... on GfFieldWithContentSetting {\n\t\t\tcontent\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment HtmlFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithLabelSetting {\n\t\t\tlabel\n\t\t}\n\t\t... on GfFieldWithContentSetting {\n\t\t\tcontent\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment FormInputFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithInputMaskSetting {\n\t\t\tinputMaskValue\n\t\t}\n\t\t... on GfFieldWithMaxLengthSetting {\n\t\t\tmaxLength\n\t\t}\n\t\t... on GfFieldWithPlaceholderSetting {\n\t\t\tplaceholder\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithSizeSetting {\n\t\t\tsize\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment FormInputFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithInputMaskSetting {\n\t\t\tinputMaskValue\n\t\t}\n\t\t... on GfFieldWithMaxLengthSetting {\n\t\t\tmaxLength\n\t\t}\n\t\t... on GfFieldWithPlaceholderSetting {\n\t\t\tplaceholder\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithSizeSetting {\n\t\t\tsize\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment CheckboxFieldChoiceFrag on GfFieldChoice {\n\t\ttext\n\t\tvalue\n\t\t... on CheckboxFieldChoice {\n\t\t\tisSelected\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment CheckboxFieldChoiceFrag on GfFieldChoice {\n\t\ttext\n\t\tvalue\n\t\t... on CheckboxFieldChoice {\n\t\t\tisSelected\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment CheckboxFieldInputFrag on GfFieldInput {\n\t\t... on GfFieldInput {\n\t\t\tid\n\t\t\tlabel\n\t\t\t... on CheckboxInputProperty {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment CheckboxFieldInputFrag on GfFieldInput {\n\t\t... on GfFieldInput {\n\t\t\tid\n\t\t\tlabel\n\t\t\t... on CheckboxInputProperty {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment CheckboxFieldFrag on FormField {\n\t\ttype\n\t\tinputType\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithChoicesSetting {\n\t\t\tchoices {\n\t\t\t\t... on GfFieldChoice {\n\t\t\t\t\t...CheckboxFieldChoiceFrag\n\t\t\t\t}\n\t\t\t}\n\t\t\tinputs {\n\t\t\t\t... on GfFieldInput {\n\t\t\t\t\t...CheckboxFieldInputFrag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment CheckboxFieldFrag on FormField {\n\t\ttype\n\t\tinputType\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithChoicesSetting {\n\t\t\tchoices {\n\t\t\t\t... on GfFieldChoice {\n\t\t\t\t\t...CheckboxFieldChoiceFrag\n\t\t\t\t}\n\t\t\t}\n\t\t\tinputs {\n\t\t\t\t... on GfFieldInput {\n\t\t\t\t\t...CheckboxFieldInputFrag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment ConsentFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithLabelSetting {\n\t\t\tlabel\n\t\t}\n\t\t... on GfFieldWithCheckboxLabelSetting {\n\t\t\tcheckboxLabel\n\t\t}\n\t\t... on GfFieldWithDescriptionSetting {\n\t\t\tdescription\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment ConsentFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithLabelSetting {\n\t\t\tlabel\n\t\t}\n\t\t... on GfFieldWithCheckboxLabelSetting {\n\t\t\tcheckboxLabel\n\t\t}\n\t\t... on GfFieldWithDescriptionSetting {\n\t\t\tdescription\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment RadioFieldFrag on FormField {\n\t\ttype\n\t\tinputType\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithOtherChoiceSetting {\n\t\t\thasOtherChoice\n\t\t}\n\t\t... on GfFieldWithChoicesSetting {\n\t\t\tchoices {\n\t\t\t\t... on GfFieldChoice {\n\t\t\t\t\ttext\n\t\t\t\t\tvalue\n\t\t\t\t\t... on RadioFieldChoice {\n\t\t\t\t\t\tisSelected\n\t\t\t\t\t\tisOtherChoice\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tinputs {\n\t\t\t\t... on GfFieldInput {\n\t\t\t\t\tid\n\t\t\t\t\tlabel\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment RadioFieldFrag on FormField {\n\t\ttype\n\t\tinputType\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithOtherChoiceSetting {\n\t\t\thasOtherChoice\n\t\t}\n\t\t... on GfFieldWithChoicesSetting {\n\t\t\tchoices {\n\t\t\t\t... on GfFieldChoice {\n\t\t\t\t\ttext\n\t\t\t\t\tvalue\n\t\t\t\t\t... on RadioFieldChoice {\n\t\t\t\t\t\tisSelected\n\t\t\t\t\t\tisOtherChoice\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tinputs {\n\t\t\t\t... on GfFieldInput {\n\t\t\t\t\tid\n\t\t\t\t\tlabel\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment SectionFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithLabelSetting {\n\t\t\tlabel\n\t\t}\n\t\t... on GfFieldWithDescriptionSetting {\n\t\t\tdescription\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment SectionFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithLabelSetting {\n\t\t\tlabel\n\t\t}\n\t\t... on GfFieldWithDescriptionSetting {\n\t\t\tdescription\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment TextAreaFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithInputMaskSetting {\n\t\t\tinputMaskValue\n\t\t}\n\t\t... on GfFieldWithMaxLengthSetting {\n\t\t\tmaxLength\n\t\t}\n\t\t... on GfFieldWithPlaceholderSetting {\n\t\t\tplaceholder\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithSizeSetting {\n\t\t\tsize\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment TextAreaFieldFrag on FormField {\n\t\ttype\n\t\t... on GfFieldWithCssClassSetting {\n\t\t\tcssClass\n\t\t}\n\t\t... on GfFieldWithInputMaskSetting {\n\t\t\tinputMaskValue\n\t\t}\n\t\t... on GfFieldWithMaxLengthSetting {\n\t\t\tmaxLength\n\t\t}\n\t\t... on GfFieldWithPlaceholderSetting {\n\t\t\tplaceholder\n\t\t}\n\t\t... on GfFieldWithRulesSetting {\n\t\t\tisRequired\n\t\t}\n\t\t... on GfFieldWithSizeSetting {\n\t\t\tsize\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment FormFieldSizeFrag on GfFieldWithSizeSetting {\n\t\tsize\n\t}\n"): (typeof documents)["\n\tfragment FormFieldSizeFrag on GfFieldWithSizeSetting {\n\t\tsize\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment FormFieldDefaultValueFrag on GfFieldWithDefaultValueSetting {\n\t\tdefaultValue\n\t}\n"): (typeof documents)["\n\tfragment FormFieldDefaultValueFrag on GfFieldWithDefaultValueSetting {\n\t\tdefaultValue\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment FormFieldConditionalLogicFrag on GfFieldWithConditionalLogicSetting {\n\t\tconditionalLogic {\n\t\t\tactionType\n\t\t\tlogicType\n\t\t\trules {\n\t\t\t\tfieldId\n\t\t\t\toperator\n\t\t\t\tvalue\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment FormFieldConditionalLogicFrag on GfFieldWithConditionalLogicSetting {\n\t\tconditionalLogic {\n\t\t\tactionType\n\t\t\tlogicType\n\t\t\trules {\n\t\t\t\tfieldId\n\t\t\t\toperator\n\t\t\t\tvalue\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment GfFormFieldFrag on FormField {\n\t\tid\n\t\tdatabaseId\n\t\tinputType\n\t\tlayoutGridColumnSpan\n\t\tlayoutSpacerGridColumnSpan\n\t\tpageNumber\n\t\ttype\n\t\tvisibility\n\t\tdisplayOnly\n\t\t... on GfFieldWithConditionalLogicSetting {\n\t\t\t...FormFieldConditionalLogicFrag\n\t\t}\n\t\t... on GfFieldWithSizeSetting {\n\t\t\t...FormFieldSizeFrag\n\t\t}\n\t\t... on GfFieldWithDefaultValueSetting {\n\t\t\t...FormFieldDefaultValueFrag\n\t\t}\n\t\t...CheckboxFieldFrag\n\t\t...ConsentFieldFrag\n\t\t...FieldWrapperFrag\n\t\t...FormInputFieldFrag\n\t\t...HtmlFieldFrag\n\t\t...RadioFieldFrag\n\t\t...SectionFieldFrag\n\t}\n"): (typeof documents)["\n\tfragment GfFormFieldFrag on FormField {\n\t\tid\n\t\tdatabaseId\n\t\tinputType\n\t\tlayoutGridColumnSpan\n\t\tlayoutSpacerGridColumnSpan\n\t\tpageNumber\n\t\ttype\n\t\tvisibility\n\t\tdisplayOnly\n\t\t... on GfFieldWithConditionalLogicSetting {\n\t\t\t...FormFieldConditionalLogicFrag\n\t\t}\n\t\t... on GfFieldWithSizeSetting {\n\t\t\t...FormFieldSizeFrag\n\t\t}\n\t\t... on GfFieldWithDefaultValueSetting {\n\t\t\t...FormFieldDefaultValueFrag\n\t\t}\n\t\t...CheckboxFieldFrag\n\t\t...ConsentFieldFrag\n\t\t...FieldWrapperFrag\n\t\t...FormInputFieldFrag\n\t\t...HtmlFieldFrag\n\t\t...RadioFieldFrag\n\t\t...SectionFieldFrag\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment GfFormsFormFrag on GfForm {\n\t\tid\n\t\tdatabaseId\n\t\tdescriptionPlacement\n\t\tlabelPlacement\n\t\tsubLabelPlacement\n\t\ttitle\n\t\tsubmitButton {\n\t\t\tconditionalLogic {\n\t\t\t\tactionType\n\t\t\t\tlogicType\n\t\t\t\trules {\n\t\t\t\t\tfieldId\n\t\t\t\t\toperator\n\t\t\t\t\tvalue\n\t\t\t\t}\n\t\t\t}\n\t\t\timageUrl\n\t\t\ttext\n\t\t\ttype\n\t\t}\n\t\tformFields {\n\t\t\tnodes {\n\t\t\t\t... on FormField {\n\t\t\t\t\t...GfFormFieldFrag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment GfFormsFormFrag on GfForm {\n\t\tid\n\t\tdatabaseId\n\t\tdescriptionPlacement\n\t\tlabelPlacement\n\t\tsubLabelPlacement\n\t\ttitle\n\t\tsubmitButton {\n\t\t\tconditionalLogic {\n\t\t\t\tactionType\n\t\t\t\tlogicType\n\t\t\t\trules {\n\t\t\t\t\tfieldId\n\t\t\t\t\toperator\n\t\t\t\t\tvalue\n\t\t\t\t}\n\t\t\t}\n\t\t\timageUrl\n\t\t\ttext\n\t\t\ttype\n\t\t}\n\t\tformFields {\n\t\t\tnodes {\n\t\t\t\t... on FormField {\n\t\t\t\t\t...GfFormFieldFrag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment GfFormBlockFrag on GravityformsForm {\n\t\tattributes {\n\t\t\tform {\n\t\t\t\t...GfFormsFormFrag\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment GfFormBlockFrag on GravityformsForm {\n\t\tattributes {\n\t\t\tform {\n\t\t\t\t...GfFormsFormFrag\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation GfFormSubmit($input: SubmitGfFormInput!) {\n\t\tsubmitGfForm(input: $input) {\n\t\t\terrors {\n\t\t\t\tmessage\n\t\t\t\tid\n\t\t\t}\n\t\t\tconfirmation {\n\t\t\t\ttype\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation GfFormSubmit($input: SubmitGfFormInput!) {\n\t\tsubmitGfForm(input: $input) {\n\t\t\terrors {\n\t\t\t\tmessage\n\t\t\t\tid\n\t\t\t}\n\t\t\tconfirmation {\n\t\t\t\ttype\n\t\t\t\tmessage\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment LeaderboardUserDataFrag on LeaderboardSubmitter {\n\t\tavatarUrl\n\t\tgithubUrl\n\t\tissueUrls\n\t\tlogin\n\t\tname\n\t\topenIssues\n\t\topenPRs\n\t\ttotalIssues\n\t\ttotalPRs\n\t\ttotalTime\n\t\ttwitter\n\t\twebsite\n\t}\n"): (typeof documents)["\n\tfragment LeaderboardUserDataFrag on LeaderboardSubmitter {\n\t\tavatarUrl\n\t\tgithubUrl\n\t\tissueUrls\n\t\tlogin\n\t\tname\n\t\topenIssues\n\t\topenPRs\n\t\ttotalIssues\n\t\ttotalPRs\n\t\ttotalTime\n\t\ttwitter\n\t\twebsite\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment LeaderboardFrag on LeaderboardData {\n\t\tpendingCount\n\t\ttotalIssueCount\n\t\ttotalPRCount\n\t\ttotalTime\n\t\tusersData {\n\t\t\t...LeaderboardUserDataFrag\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment LeaderboardFrag on LeaderboardData {\n\t\tpendingCount\n\t\ttotalIssueCount\n\t\ttotalPRCount\n\t\ttotalTime\n\t\tusersData {\n\t\t\t...LeaderboardUserDataFrag\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment ProjectFrag on Project {\n\t\tid\n\t\ttitle\n\t\tprojectLinks {\n\t\t\trepoUrl\n\t\t}\n\t\tprojectTypes {\n\t\t\tnodes {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t\tfeaturedImage {\n\t\t\tnode {\n\t\t\t\tsourceUrl(size: MEDIUM_LARGE)\n\t\t\t\taltText\n\t\t\t\tmediaDetails {\n\t\t\t\t\twidth\n\t\t\t\t\theight\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment ProjectFrag on Project {\n\t\tid\n\t\ttitle\n\t\tprojectLinks {\n\t\t\trepoUrl\n\t\t}\n\t\tprojectTypes {\n\t\t\tnodes {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t\tfeaturedImage {\n\t\t\tnode {\n\t\t\t\tsourceUrl(size: MEDIUM_LARGE)\n\t\t\t\taltText\n\t\t\t\tmediaDetails {\n\t\t\t\t\twidth\n\t\t\t\t\theight\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment ProjectsFrag on RootQueryToProjectConnection {\n\t\tnodes {\n\t\t\t...ProjectFrag\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment ProjectsFrag on RootQueryToProjectConnection {\n\t\tnodes {\n\t\t\t...ProjectFrag\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment SeoFrag on RankMathSeo {\n\t\ttitle\n\t\tdescription\n\t\trobots\n\t\topenGraph {\n\t\t\ttype\n\t\t\tlocale\n\t\t\ttitle\n\t\t\tdescription\n\t\t\timage {\n\t\t\t\tsecureUrl\n\t\t\t\theight\n\t\t\t\twidth\n\t\t\t}\n\t\t\turl\n\t\t\tsiteName\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment SeoFrag on RankMathSeo {\n\t\ttitle\n\t\tdescription\n\t\trobots\n\t\topenGraph {\n\t\t\ttype\n\t\t\tlocale\n\t\t\ttitle\n\t\t\tdescription\n\t\t\timage {\n\t\t\t\tsecureUrl\n\t\t\t\theight\n\t\t\t\twidth\n\t\t\t}\n\t\t\turl\n\t\t\tsiteName\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment BlockContentEditorBlockFrag on EditorBlock {\n\t\t__typename\n\t\trenderedHtml\n\t\tcssClassNames\n\t\tname\n\t\tclientId\n\t\tparentClientId\n\t\tinnerBlocks {\n\t\t\t__typename\n\t\t}\n\t\t... on CoreButton {\n\t\t\t...CoreButtonFrag\n\t\t}\n\t\t... on CoreButtons {\n\t\t\t...CoreButtonsFrag\n\t\t}\n\t\t... on CoreColumn {\n\t\t\t...CoreColumnFrag\n\t\t}\n\t\t... on CoreColumns {\n\t\t\t...CoreColumnsFrag\n\t\t}\n\t\t... on CoreGroup {\n\t\t\t...CoreGroupFrag\n\t\t}\n\t\t... on CoreHeading {\n\t\t\t...CoreHeadingFrag\n\t\t}\n\t\t... on CoreParagraph {\n\t\t\t...CoreParagraphFrag\n\t\t}\n\t\t... on GravityformsForm {\n\t\t\t...GfFormBlockFrag\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment BlockContentEditorBlockFrag on EditorBlock {\n\t\t__typename\n\t\trenderedHtml\n\t\tcssClassNames\n\t\tname\n\t\tclientId\n\t\tparentClientId\n\t\tinnerBlocks {\n\t\t\t__typename\n\t\t}\n\t\t... on CoreButton {\n\t\t\t...CoreButtonFrag\n\t\t}\n\t\t... on CoreButtons {\n\t\t\t...CoreButtonsFrag\n\t\t}\n\t\t... on CoreColumn {\n\t\t\t...CoreColumnFrag\n\t\t}\n\t\t... on CoreColumns {\n\t\t\t...CoreColumnsFrag\n\t\t}\n\t\t... on CoreGroup {\n\t\t\t...CoreGroupFrag\n\t\t}\n\t\t... on CoreHeading {\n\t\t\t...CoreHeadingFrag\n\t\t}\n\t\t... on CoreParagraph {\n\t\t\t...CoreParagraphFrag\n\t\t}\n\t\t... on GravityformsForm {\n\t\t\t...GfFormBlockFrag\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment CoreButtonFrag on CoreButton {\n\t\tattributes {\n\t\t\tanchor\n\t\t\tgradient\n\t\t\ttext\n\t\t\ttextAlign\n\t\t\ttextColor\n\t\t\tstyle\n\t\t\tfontSize\n\t\t\tfontFamily\n\t\t\tlinkTarget\n\t\t\trel\n\t\t\turl\n\t\t\tbackgroundColor\n\t\t\tcssClassName\n\t\t\tlinkClassName\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment CoreButtonFrag on CoreButton {\n\t\tattributes {\n\t\t\tanchor\n\t\t\tgradient\n\t\t\ttext\n\t\t\ttextAlign\n\t\t\ttextColor\n\t\t\tstyle\n\t\t\tfontSize\n\t\t\tfontFamily\n\t\t\tlinkTarget\n\t\t\trel\n\t\t\turl\n\t\t\tbackgroundColor\n\t\t\tcssClassName\n\t\t\tlinkClassName\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment CoreButtonsFrag on CoreButtons {\n\t\tattributes {\n\t\t\tcssClassName\n\t\t\talign\n\t\t\tanchor\n\t\t\tfontFamily\n\t\t\tfontSize\n\t\t\tlayout\n\t\t\tstyle\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment CoreButtonsFrag on CoreButtons {\n\t\tattributes {\n\t\t\tcssClassName\n\t\t\talign\n\t\t\tanchor\n\t\t\tfontFamily\n\t\t\tfontSize\n\t\t\tlayout\n\t\t\tstyle\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment CoreColumnFrag on CoreColumn {\n\t\tattributes {\n\t\t\tanchor\n\t\t\tborderColor\n\t\t\tbackgroundColor\n\t\t\tcssClassName\n\t\t\tclassName\n\t\t\tfontFamily\n\t\t\tfontSize\n\t\t\tgradient\n\t\t\tlayout\n\t\t\tstyle\n\t\t\ttextColor\n\t\t\tverticalAlignment\n\t\t\twidth\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment CoreColumnFrag on CoreColumn {\n\t\tattributes {\n\t\t\tanchor\n\t\t\tborderColor\n\t\t\tbackgroundColor\n\t\t\tcssClassName\n\t\t\tclassName\n\t\t\tfontFamily\n\t\t\tfontSize\n\t\t\tgradient\n\t\t\tlayout\n\t\t\tstyle\n\t\t\ttextColor\n\t\t\tverticalAlignment\n\t\t\twidth\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment CoreColumnsFrag on CoreColumns {\n\t\tattributes {\n\t\t\talign\n\t\t\tanchor\n\t\t\tlayout\n\t\t\tcssClassName\n\t\t\tisStackedOnMobile\n\t\t\tverticalAlignment\n\t\t\tborderColor\n\t\t\tbackgroundColor\n\t\t\tfontSize\n\t\t\tfontFamily\n\t\t\tstyle\n\t\t\ttextColor\n\t\t\tgradient\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment CoreColumnsFrag on CoreColumns {\n\t\tattributes {\n\t\t\talign\n\t\t\tanchor\n\t\t\tlayout\n\t\t\tcssClassName\n\t\t\tisStackedOnMobile\n\t\t\tverticalAlignment\n\t\t\tborderColor\n\t\t\tbackgroundColor\n\t\t\tfontSize\n\t\t\tfontFamily\n\t\t\tstyle\n\t\t\ttextColor\n\t\t\tgradient\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment CoreGroupFrag on CoreGroup {\n\t\trenderedHtml\n\t\tcssClassNames\n\t\tattributes {\n\t\t\talign\n\t\t\tanchor\n\t\t\tbackgroundColor\n\t\t\tborderColor\n\t\t\tclassName\n\t\t\tfontFamily\n\t\t\tfontSize\n\t\t\tgradient\n\t\t\tlayout\n\t\t\tstyle\n\t\t\ttagName\n\t\t\ttextColor\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment CoreGroupFrag on CoreGroup {\n\t\trenderedHtml\n\t\tcssClassNames\n\t\tattributes {\n\t\t\talign\n\t\t\tanchor\n\t\t\tbackgroundColor\n\t\t\tborderColor\n\t\t\tclassName\n\t\t\tfontFamily\n\t\t\tfontSize\n\t\t\tgradient\n\t\t\tlayout\n\t\t\tstyle\n\t\t\ttagName\n\t\t\ttextColor\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment CoreHeadingFrag on CoreHeading {\n\t\tattributes {\n\t\t\talign\n\t\t\tanchor\n\t\t\tbackgroundColor\n\t\t\tcontent\n\t\t\tfontFamily\n\t\t\tfontSize\n\t\t\tgradient\n\t\t\tlevel\n\t\t\tstyle\n\t\t\ttextAlign\n\t\t\ttextColor\n\t\t\tcssClassName\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment CoreHeadingFrag on CoreHeading {\n\t\tattributes {\n\t\t\talign\n\t\t\tanchor\n\t\t\tbackgroundColor\n\t\t\tcontent\n\t\t\tfontFamily\n\t\t\tfontSize\n\t\t\tgradient\n\t\t\tlevel\n\t\t\tstyle\n\t\t\ttextAlign\n\t\t\ttextColor\n\t\t\tcssClassName\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment CoreParagraphFrag on CoreParagraph {\n\t\tcssClassNames\n\t\tattributes {\n\t\t\tcssClassName\n\t\t\tbackgroundColor\n\t\t\tcontent\n\t\t\tstyle\n\t\t\ttextColor\n\t\t\tfontSize\n\t\t\tfontFamily\n\t\t\tdirection\n\t\t\tdropCap\n\t\t\tgradient\n\t\t\talign\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment CoreParagraphFrag on CoreParagraph {\n\t\tcssClassNames\n\t\tattributes {\n\t\t\tcssClassName\n\t\t\tbackgroundColor\n\t\t\tcontent\n\t\t\tstyle\n\t\t\ttextColor\n\t\t\tfontSize\n\t\t\tfontFamily\n\t\t\tdirection\n\t\t\tdropCap\n\t\t\tgradient\n\t\t\talign\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment CoreSeparatorFrag on CoreSeparator {\n\t\tcssClassNames\n\t\tattributes {\n\t\t\talign\n\t\t\tanchor\n\t\t\topacity\n\t\t\tgradient\n\t\t\tbackgroundColor\n\t\t\tstyle\n\t\t\tcssClassName\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment CoreSeparatorFrag on CoreSeparator {\n\t\tcssClassNames\n\t\tattributes {\n\t\t\talign\n\t\t\tanchor\n\t\t\topacity\n\t\t\tgradient\n\t\t\tbackgroundColor\n\t\t\tstyle\n\t\t\tcssClassName\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment PostPageSettingsFrag on Post {\n\t\tpageSettings {\n\t\t\taxewpPageHeader\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment PostPageSettingsFrag on Post {\n\t\tpageSettings {\n\t\t\taxewpPageHeader\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment PageSettingsFrag on Page {\n\t\tpageSettings {\n\t\t\taxewpPageHeader\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment PageSettingsFrag on Page {\n\t\tpageSettings {\n\t\t\taxewpPageHeader\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment TitleFrag on NodeWithTitle {\n\t\ttitle\n\t}\n"): (typeof documents)["\n\tfragment TitleFrag on NodeWithTitle {\n\t\ttitle\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment ContentNodeFrag on ContentNode {\n\t\tdate\n\t}\n"): (typeof documents)["\n\tfragment ContentNodeFrag on ContentNode {\n\t\tdate\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment ContentEditorFrag on NodeWithContentEditor {\n\t\tcontent\n\t}\n"): (typeof documents)["\n\tfragment ContentEditorFrag on NodeWithContentEditor {\n\t\tcontent\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment AuthorNodeFrag on NodeWithAuthor {\n\t\tauthor {\n\t\t\tnode {\n\t\t\t\tname\n\t\t\t\turi\n\t\t\t\tavatar {\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment AuthorNodeFrag on NodeWithAuthor {\n\t\tauthor {\n\t\t\tnode {\n\t\t\t\tname\n\t\t\t\turi\n\t\t\t\tavatar {\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment MediaItemFrag on MediaItem {\n\t\tid\n\t\tsourceUrl\n\t\taltText\n\t\tmediaDetails {\n\t\t\twidth\n\t\t\theight\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment MediaItemFrag on MediaItem {\n\t\tid\n\t\tsourceUrl\n\t\taltText\n\t\tmediaDetails {\n\t\t\twidth\n\t\t\theight\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment MainProjectFrag on Project {\n\t\ttitle\n\t\tslug\n\t\tprojectLinks {\n\t\t\trepoUrl\n\t\t}\n\t\tprojectTypes {\n\t\t\tnodes {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment MainProjectFrag on Project {\n\t\ttitle\n\t\tslug\n\t\tprojectLinks {\n\t\t\trepoUrl\n\t\t}\n\t\tprojectTypes {\n\t\t\tnodes {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment ContentTypeFrag on ContentType {\n\t\tname\n\t\tcontentNodes {\n\t\t\tnodes {\n\t\t\t\t... on Project {\n\t\t\t\t\t...MainProjectFrag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment ContentTypeFrag on ContentType {\n\t\tname\n\t\tcontentNodes {\n\t\t\tnodes {\n\t\t\t\t... on Project {\n\t\t\t\t\t...MainProjectFrag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment SingularPostCategoryFrag on Category {\n\t\tid\n\t\tname\n\t\turi\n\t}\n\t"): (typeof documents)["\n\tfragment SingularPostCategoryFrag on Category {\n\t\tid\n\t\tname\n\t\turi\n\t}\n\t"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tfragment SingularPostFrag on Post {\n\t\tcategories {\n\t\t\tnodes {\n\t\t\t\t...SingularPostCategoryFrag\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment SingularPostFrag on Post {\n\t\tcategories {\n\t\t\tnodes {\n\t\t\t\t...SingularPostCategoryFrag\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery Get404Page {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t}\n"): (typeof documents)["\n\tquery Get404Page {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetProjectsArchiveNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: page(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\t...TitleFrag\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t\t...PageSettingsFrag\n\t\t}\n\t\tprojects(first: 20, where: { orderby: { field: MENU_ORDER, order: ASC } }) {\n\t\t\t...ProjectsFrag\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetProjectsArchiveNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: page(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\t...TitleFrag\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t\t...PageSettingsFrag\n\t\t}\n\t\tprojects(first: 20, where: { orderby: { field: MENU_ORDER, order: ASC } }) {\n\t\t\t...ProjectsFrag\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetFrontPageNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: page(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\ttitle\n\t\t\tdate\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetFrontPageNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: page(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\ttitle\n\t\t\tdate\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetIndexNode($uri: String!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: nodeByUri(uri: $uri) {\n\t\t\t... on Page {\n\t\t\t\t...NodeWithSeoFrag\n\t\t\t}\n\t\t\tid\n\t\t\turi\n\t\t\t... on ContentType {\n\t\t\t\t...ContentTypeFrag\n\t\t\t\t...NodeWithSeoFrag\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetIndexNode($uri: String!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: nodeByUri(uri: $uri) {\n\t\t\t... on Page {\n\t\t\t\t...NodeWithSeoFrag\n\t\t\t}\n\t\t\tid\n\t\t\turi\n\t\t\t... on ContentType {\n\t\t\t\t...ContentTypeFrag\n\t\t\t\t...NodeWithSeoFrag\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetSpringCleaningPageNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: page(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\ttitle\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t\tpageSettings {\n\t\t\t\taxewpPageHeader\n\t\t\t}\n\t\t}\n\t\tgfForm(id: 2, idType: DATABASE_ID) {\n\t\t\t...GfFormsFormFrag\n\t\t}\n\t\tleaderboard {\n\t\t\t...LeaderboardFrag\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetSpringCleaningPageNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: page(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\ttitle\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t\tpageSettings {\n\t\t\t\taxewpPageHeader\n\t\t\t}\n\t\t}\n\t\tgfForm(id: 2, idType: DATABASE_ID) {\n\t\t\t...GfFormsFormFrag\n\t\t}\n\t\tleaderboard {\n\t\t\t...LeaderboardFrag\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetPageNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: page(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\ttitle\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t\tpageSettings {\n\t\t\t\taxewpPageHeader\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetPageNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: page(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\ttitle\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t\tpageSettings {\n\t\t\t\taxewpPageHeader\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetSingularNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: post(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\ttitle\n\t\t\t...ContentNodeFrag\n\t\t\t...ContentEditorFrag\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t\t...AuthorNodeFrag\n\t\t\t...SingularPostFrag\n\t\t\t...PostPageSettingsFrag\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetSingularNode($uri: ID!) {\n\t\tsiteConfig {\n\t\t\t...SiteIdentityFrag\n\t\t\t...FooterSettingsFrag\n\t\t}\n\t\t...PrimaryMenuItemsFrag\n\t\tcurrentPage: post(id: $uri, idType: URI) {\n\t\t\tid\n\t\t\turi\n\t\t\ttitle\n\t\t\t...ContentNodeFrag\n\t\t\t...ContentEditorFrag\n\t\t\teditorBlocks {\n\t\t\t\t...BlockContentEditorBlockFrag\n\t\t\t}\n\t\t\t...FeaturedImageFrag\n\t\t\t...NodeWithSeoFrag\n\t\t\t...AuthorNodeFrag\n\t\t\t...SingularPostFrag\n\t\t\t...PostPageSettingsFrag\n\t\t}\n\t}\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;