import { WordPressTemplate } from '@faustwp/core/dist/mjs/getWordPressProps';
import dynamic from 'next/dynamic';
import { gql } from '@graphqlTypes/gql';

const Component = dynamic( () =>
	import( '../features/WpTemplates/Page' ).then( ( mod ) => mod.Page )
);

const Page: WordPressTemplate = ( props ) => {
	return <Component { ...props } />;
};

Page.variables = ( { uri } ) => {
	return {
		uri,
	};
};

Page.query = gql( `
	query GetPageNode($uri: ID!) {
		siteConfig {
			...SiteIdentityFrag
			...FooterSettingsFrag
		}
		...PrimaryMenuItemsFrag
		currentPage: page(id: $uri, idType: URI) {
			id
			uri
			title
			editorBlocks {
				...BlockContentEditorBlockFrag
			}
			...FeaturedImageFrag
			...NodeWithSeoFrag
			pageSettings {
				axewpPageHeader
			}
		}
	}
` );

export default Page;
