import dynamic from 'next/dynamic';
import { CoreParagraphFragFragmentDoc } from '@graphqlTypes/graphql';
import type { WordPressBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksProvider';

const Component = dynamic( () =>
	import( '../features/WpBlocks/components/CoreParagraph' ).then( ( mod ) => mod.CoreParagraph )
);

export const CoreParagraph: WordPressBlock = ( props ) => {
	return <Component { ...props } />;
};

CoreParagraph.fragments = {
	key: `CoreParagraphFrag`,
	entry: CoreParagraphFragFragmentDoc,
};
CoreParagraph.config = {
	name: 'CoreParagraph',
};
CoreParagraph.displayName = 'CoreParagraph';
