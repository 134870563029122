import { NormalizedCacheObject } from '@apollo/client';
import { getApolloClientLinkChain } from '@/lib/ApolloClient/getApolloClientLinkChain';
import type { ApolloClientOptions } from '@apollo/client/core/ApolloClient';
import type { FaustHooks } from '@faustwp/core/dist/mjs/wpHooks/overloads';

class ApolloClientOptionsPlugin {
	apply( { addFilter }: FaustHooks ) {
		addFilter(
			'apolloClientOptions',
			'faust',
			( apolloClientOptions ): ApolloClientOptions< NormalizedCacheObject > => {
				return {
					...apolloClientOptions,
					link: getApolloClientLinkChain(),
				};
			}
		);
	}
}

export default ApolloClientOptionsPlugin;
