import { createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { getConfig } from '@faustwp/core/dist/mjs/config';
import { getGraphqlEndpoint } from '@faustwp/core/dist/mjs/lib/getGraphqlEndpoint';
import { sha256 } from 'js-sha256';

async function loggingFetch(
	input: URL | RequestInfo,
	init?: RequestInit | undefined
): Promise< Response > {
	const body = init?.body ? JSON.parse( init.body.toString() ) : {};

	const start = Date.now();
	console.log(
		`${ new Date().toISOString().slice( -13 ) } 📡 Sending ${ body.operationName } request`
	);
	console.log( `${ new Date().toISOString().slice( -13 ) } 🤯 Request Headers: `, {
		...init?.headers,
	} );

	if ( body?.extensions?.persistedQuery ) {
		console.log( `${ new Date().toISOString().slice( -13 ) } 💪 Persistent queries: `, {
			...body.extensions.persistedQueries,
		} );
	}

	const response = await fetch( input, init );
	console.log(
		`${ new Date().toISOString().slice( -13 ) } 📡 Received ${ body.operationName } response in ${
			Date.now() - start
		}ms`
	);
	console.log( `${ new Date().toISOString().slice( -13 ) } 🎩 Response Headers:`, {
		...response.headers,
	} );

	return {
		...response,

		async text() {
			const textStart = Date.now();
			const result = await response.text();
			console.log(
				`${ new Date().toISOString().slice( -13 ) } ⚙️ Read ${
					body.operationName
				} response body in ${ Date.now() - textStart }ms (${ result.length } bytes)`
			);
			return result;
		},
	};
}

export function getApolloClientLinkChain() {
	const { usePersistedQueries, useGETForQueries } = getConfig();

	let linkChain = createHttpLink( {
		uri: getGraphqlEndpoint(),
		credentials: 'omit',
		// Without this, no Origin is set despite the fact that the request is cross-origin.
		headers: {
			Origin: process.env.NEXT_PUBLIC_SITE_URL,
		},
		fetchOptions: {
			mode: 'cors',
			referrerPolicy: 'strict-origin-when-cross-origin',
		},
		fetch: process.env.NODE_ENV === 'development' ? loggingFetch : undefined,
	} );

	linkChain = onError( ( { graphQLErrors, networkError } ) => {
		if ( graphQLErrors ) {
			console.warn( { ...graphQLErrors } );
			graphQLErrors.forEach( ( { message, locations, path } ) =>
				console.log(
					`[GraphQL error]: Message: ${ message }, Path: ${ path }`,
					locations && `Location:`,
					locations && { ...locations }
				)
			);
		}

		if ( networkError ) {
			console.log( `[Network error]: ${ networkError }` );
		}
	} ).concat( linkChain );

	// If the user requested to use persisted queries, apply the link.
	if ( usePersistedQueries ) {
		linkChain = createPersistedQueryLink( {
			sha256,
			useGETForHashedQueries: useGETForQueries,
		} ).concat( linkChain );
	}

	return linkChain;
}
