import dynamic from 'next/dynamic';
import { CoreGroupFragFragmentDoc } from '@graphqlTypes/graphql';
import type { WordPressBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksProvider';

const Component = dynamic( () =>
	import( '../features/WpBlocks/components/CoreGroup' ).then( ( mod ) => mod.CoreGroup )
);

export const CoreGroup: WordPressBlock = ( props ) => {
	return <Component { ...props } />;
};

CoreGroup.fragments = {
	entry: CoreGroupFragFragmentDoc,
	key: `CoreGroupFrag`,
};

CoreGroup.displayName = 'CoreGroup';
CoreGroup.config = {
	name: 'CoreGroup',
};
