import { CoreHeading } from '@faustwp/blocks/dist/mjs/blocks/CoreHeading';
import { GfFormsFormBlock } from '@/features/GravityForms/block';
import { CoreButton } from './CoreButton';
import { CoreButtons } from './CoreButtons';
import { CoreColumn } from './CoreColumn';
import { CoreColumns } from './CoreColumns';
import { CoreGroup } from './CoreGroup';
import { CoreParagraph } from './CoreParagraph';
import { CoreSeparator } from './CoreSeparator';
import type { WordPressBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksProvider';

const blocks = {
	GfFormsFormBlock,
	CoreButton,
	CoreButtons,
	CoreColumns,
	CoreColumn,
	CoreGroup,
	CoreHeading,
	CoreParagraph,
	CoreSeparator,
};

export default blocks as unknown as { [ key: string ]: WordPressBlock };
