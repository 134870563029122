import { WordPressTemplate } from '@faustwp/core/dist/mjs/getWordPressProps';
import dynamic from 'next/dynamic';
import { gql } from '@graphqlTypes/gql';

const Component = dynamic( () =>
	import( '../features/WpTemplates/SpringPage' ).then( ( mod ) => mod.SpringPage )
);

const PageSpringCleaning: WordPressTemplate = ( props ) => {
	return <Component { ...props } />;
};

PageSpringCleaning.variables = ( { uri } ) => {
	return {
		uri,
	};
};

PageSpringCleaning.query = gql( `
	query GetSpringCleaningPageNode($uri: ID!) {
		siteConfig {
			...SiteIdentityFrag
			...FooterSettingsFrag
		}
		...PrimaryMenuItemsFrag
		currentPage: page(id: $uri, idType: URI) {
			id
			uri
			title
			editorBlocks {
				...BlockContentEditorBlockFrag
			}
			...FeaturedImageFrag
			...NodeWithSeoFrag
			pageSettings {
				axewpPageHeader
			}
		}
		gfForm(id: 2, idType: DATABASE_ID) {
			...GfFormsFormFrag
		}
		leaderboard {
			...LeaderboardFrag
		}
	}
` );

export default PageSpringCleaning;
