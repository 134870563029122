import { WordPressTemplate } from '@faustwp/core/dist/mjs/getWordPressProps';
import dynamic from 'next/dynamic';
import { gql } from '@graphqlTypes/gql';

const Archive = dynamic( () =>
	import( '../features/WpTemplates/ProjectsArchive' ).then( ( mod ) => mod.ProjectsArchive )
);

const ProjectsArchive: WordPressTemplate = ( props ) => {
	return <Archive { ...props } />;
};

ProjectsArchive.variables = ( { uri } ) => {
	return {
		uri,
	};
};

ProjectsArchive.query = gql( `
	query GetProjectsArchiveNode($uri: ID!) {
		siteConfig {
			...SiteIdentityFrag
			...FooterSettingsFrag
		}
		...PrimaryMenuItemsFrag
		currentPage: page(id: $uri, idType: URI) {
			id
			uri
			...TitleFrag
			editorBlocks {
				...BlockContentEditorBlockFrag
			}
			...FeaturedImageFrag
			...NodeWithSeoFrag
			...PageSettingsFrag
		}
		projects(first: 20, where: { orderby: { field: MENU_ORDER, order: ASC } }) {
			...ProjectsFrag
		}
	}
` );

export default ProjectsArchive;
