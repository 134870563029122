import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as ToastPrimitives from '@radix-ui/react-toast';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef, ElementRef, ComponentPropsWithoutRef, ReactElement } from 'react';
import { cn } from '@/utils/helpers/cn';

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = forwardRef<
	ElementRef< typeof ToastPrimitives.Viewport >,
	ComponentPropsWithoutRef< typeof ToastPrimitives.Viewport >
>( ( { className, ...props }, ref ) => (
	<ToastPrimitives.Viewport
		ref={ ref }
		className={ cn(
			'fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]',
			className
		) }
		{ ...props }
	/>
) );
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastVariants = cva(
	'group pointer-events-auto relative flex w-full items-center justify-between space-x-2 overflow-hidden rounded-md border p-4 pr-6 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full',
	{
		variants: {
			variant: {
				default: 'border bg-background',
				destructive:
					'destructive group border-destructive bg-destructive text-destructive-foreground',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	}
);

const Toast = forwardRef<
	ElementRef< typeof ToastPrimitives.Root >,
	ComponentPropsWithoutRef< typeof ToastPrimitives.Root > & VariantProps< typeof toastVariants >
>( ( { className, variant, ...props }, ref ) => {
	return (
		<ToastPrimitives.Root
			ref={ ref }
			className={ cn( toastVariants( { variant } ), className ) }
			{ ...props }
		/>
	);
} );
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = forwardRef<
	ElementRef< typeof ToastPrimitives.Action >,
	ComponentPropsWithoutRef< typeof ToastPrimitives.Action >
>( ( { className, ...props }, ref ) => (
	<ToastPrimitives.Action
		ref={ ref }
		className={ cn(
			'inline-flex h-8 shrink-0 items-center justify-center rounded-md border bg-transparent px-3 text-sm font-medium transition-colors hover:bg-secondary focus:outline-none focus:ring-1 focus:ring-ring disabled:pointer-events-none disabled:opacity-50 group-[.destructive]:border-muted/40 group-[.destructive]:hover:border-destructive/30 group-[.destructive]:hover:bg-destructive group-[.destructive]:hover:text-destructive-foreground group-[.destructive]:focus:ring-destructive',
			className
		) }
		{ ...props }
	/>
) );
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = forwardRef<
	ElementRef< typeof ToastPrimitives.Close >,
	ComponentPropsWithoutRef< typeof ToastPrimitives.Close >
>( ( { className, ...props }, ref ) => (
	<ToastPrimitives.Close
		ref={ ref }
		className={ cn(
			'group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-offset-red-600 absolute right-1 top-1 rounded-md p-1 text-foreground/50 opacity-0 transition-opacity hover:text-foreground focus:opacity-100 focus:outline-none focus:ring-1 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:focus:ring-red-400',
			className
		) }
		toast-close=""
		{ ...props }
	>
		<FontAwesomeIcon icon={ faTimes } className="h-4 w-4" />
	</ToastPrimitives.Close>
) );
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = forwardRef<
	ElementRef< typeof ToastPrimitives.Title >,
	ComponentPropsWithoutRef< typeof ToastPrimitives.Title >
>( ( { className, ...props }, ref ) => (
	<ToastPrimitives.Title
		ref={ ref }
		className={ cn( 'text-sm font-semibold [&+div]:text-xs', className ) }
		{ ...props }
	/>
) );
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = forwardRef<
	ElementRef< typeof ToastPrimitives.Description >,
	ComponentPropsWithoutRef< typeof ToastPrimitives.Description >
>( ( { className, ...props }, ref ) => (
	<ToastPrimitives.Description
		ref={ ref }
		className={ cn( 'text-sm opacity-90', className ) }
		{ ...props }
	/>
) );
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProps = ComponentPropsWithoutRef< typeof Toast >;

type ToastActionElement = ReactElement< typeof ToastAction >;

export {
	type ToastProps,
	type ToastActionElement,
	ToastProvider,
	ToastViewport,
	Toast,
	ToastTitle,
	ToastDescription,
	ToastClose,
	ToastAction,
};
