import dynamic from 'next/dynamic';
import { CoreButtonFragmentProps } from '@/features/WpBlocks/components/CoreButton';
import { CoreButtonFragFragmentDoc } from '@graphqlTypes/graphql';

const Component = dynamic( () =>
	import( '../features/WpBlocks/components/CoreButton' ).then( ( mod ) => mod.CoreButton )
);

export const CoreButton = ( props: CoreButtonFragmentProps ) => {
	return <Component { ...props } />;
};

CoreButton.fragments = {
	key: `CoreButtonFrag`,
	entry: CoreButtonFragFragmentDoc,
};
CoreButton.config = {
	name: 'CoreButton',
};
CoreButton.displayName = 'CoreButton';
