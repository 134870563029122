import { setConfig } from '@faustwp/core/dist/mjs/config/';
import ApolloClientOptionsPlugin from '@/plugins/ApolloClientOptionsPlugin';
import possibleTypes from './possibleTypes.json';
import templates from './src/wp-templates/index';

/**
 * @type {import('@faustwp/core').FaustConfig}
 */
export default setConfig( {
	templates,
	plugins: [ new ApolloClientOptionsPlugin() ],
	experimentalPlugins: [],
	possibleTypes,
	usePersistedQueries: false,
} );
