import projects from './archive-project';
import front from './front-page';
import index from './main';
import page from './page';
import springCleaning from './page-spring';
import singular from './singular';

const templates = {
	'front-page': front,
	'page-projects': projects,
	'page-spring': springCleaning,
	page,
	singular,
	index,
};

export default templates;
