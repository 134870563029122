import '../../faust.config';
import { WordPressBlocksProvider } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksProvider';
import { fromThemeJson } from '@faustwp/blocks/dist/mjs/utils/';
import { FaustProvider } from '@faustwp/core/dist/mjs/components/FaustProvider';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { StrictMode } from 'react';
import blocks from '@/blocks/index';
import { Toaster } from '@/components/ui/toaster';
import themeJson from '../styles/theme.json';
import type { AppProps } from 'next/app';

const NextProgress = dynamic( () => import( 'next-progress' ) );

// Styles
import '../styles/tailwind.css';
import '../styles/index.css';
import '../styles/wordpress.scss';

const fromBlockThemeJson = fromThemeJson( themeJson );

const MyApp = ( { Component, pageProps }: AppProps ) => {
	const router = useRouter();

	return (
		<StrictMode>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			</Head>

			<NextProgress
				delay={ 300 }
				color="#81d7d7" // blue-900
			/>

			<FaustProvider pageProps={ pageProps }>
				<WordPressBlocksProvider
					config={ {
						blocks,
						theme: fromBlockThemeJson,
					} }
				>
					<Component { ...pageProps } key={ router.asPath } />
				</WordPressBlocksProvider>
			</FaustProvider>
			<Toaster />
		</StrictMode>
	);
};

export default MyApp;
